<template>
  <v-card :loading="loading" elevation="0">
    <div class="my-2">
      <h3 class="my-4 d-flex">
        Daftar Nilai
        <v-spacer></v-spacer>
        <v-btn icon @click="getList(params)" class="flex-right">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </h3>
      <p v-if="scores.length == 0" class="text-center py-10">Belum ada data.</p>
      <v-card
        class="my-1"
        outlined
        elevation="4"
        v-for="(score, i) in scores"
        :key="i"
        @click="getDetail(score)"
      >
        <v-row>
          <v-col cols="6" class="py-1">
            <v-card-title style="font-size: 1em">{{ score.subjects_name }}</v-card-title>
            <v-card-subtitle style="font-size: 0.8em" class="mb-0 pb-1">{{ score.student_fullname }}<br>{{ score.exam_start_time }}</v-card-subtitle>
            <v-card-text>
              <div>
                <v-chip
                  style="font-size: 0.6em"
                  class="p-1 mr-1"
                  color="primary"
                  >{{ score.exam_type }}</v-chip
                >
                <v-chip style="font-size: 0.6em" color="p-1 green" dark>{{
                  score.exam_category
                }}</v-chip>
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="6" class="py-1">
            <v-card-text class="d-flex flex-column-reverse">
              <div class="pa-2 text-right text-h5">{{ score.score }}</div>
              <div style="font-size: 0.8em" class="pa-2 text-right text-bold">
                Skor
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="py-1">
            <span style="font-size: 0.7em" class="mx-4"
              >* {{ score.academic_year_year_text }}</span
            >
          </v-col>
        </v-row>
      </v-card>

      <div class="my-2">
        <v-row dense>
          <v-col class="d-flex justify-center my-4">
            <v-btn v-if="found == true && foundAnn == 10" @click="loadMore"
              >Load more..</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <RightBar ref="myRightBar" title="Detail">
      <v-row v-for="(dtl, ii) in selectedScores" :key="ii" class="my-0">
        <v-col class="py-1">
          <div class="dtl-label">{{ dtl.label }}</div>
          <div class="dtl-value">{{ dtl.value }}</div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col>
          <v-btn @click="$refs.myRightBar.hide()">Tutup</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </v-card>
</template>
<script>
import RightBar from "@/layouts/RightBar.vue";

export default {
  data: () => ({
    search: null,
    loading: null,
    scores: [],
    params: {},
    found: null,
    foundAnn: null,
    limit: 0,
    selectedScore: {},
    selectedScores: [],
  }),

  components: {
    RightBar,
  },

  methods: {
    async getList() {
      try {
        this.loading = true;
        this.scores = [];
        this.params = this.$route.query;
        this.params.page = this.page;

        if (this.page < 2) {
          this.scores = [];
        }

        var data = {
          params: this.params,
        };
        await this.$axios.get("nilai/list", data).then((res) => {
          this.loading = false;
          if (res.data.status == "success") {
            this.page = res.data.data.page;
            this.limit = res.data.data.limit;

            let _this = this;
            if (res.data.data.scores.length > 0) {
              for (var i = 0; i < res.data.data.scores.length; i++) {
                _this.scores.push(res.data.data.scores[i]);
              }
              this.found = true;
            } else {
              this.found = false;
            }
            this.foundAnn = this.scores.length;
            this.found = true;
          } else {
            this.scores = [];
            this.found = false;
          }
        });
      } catch (error) {
        this.loading = false;
        this.showAlert("error", error);
      }
    },

    getDetail(selectedScore){
      this.selectedScore = selectedScore
      this.selectedScores = []
      this.selectedScores.push({ label: 'NIS Siswa', value: this.selectedScore.student_nis })
      this.selectedScores.push({ label: 'Nama Siswa', value: this.selectedScore.student_fullname })
      this.selectedScores.push({ label: 'Tahun Ajaran', value: this.selectedScore.academic_year_year_text })
      this.selectedScores.push({ label: 'Kelas', value: this.selectedScore.classname })
      this.selectedScores.push({ label: 'Mata Pelajaran', value: this.selectedScore.subjects_name })
      this.selectedScores.push({ label: 'Guru', value: this.selectedScore.teacher_firstname })
      this.selectedScores.push({ label: 'Kode Ujian', value: this.selectedScore.exam_code })
      this.selectedScores.push({ label: 'Ujian', value: this.selectedScore.str_type })
      this.selectedScores.push({ label: 'Tipe', value: this.selectedScore.exam_type })
      this.selectedScores.push({ label: 'Kategori', value: this.selectedScore.exam_category })
      this.selectedScores.push({ label: 'Waktu', value: this.selectedScore.exam_time_length })
      this.selectedScores.push({ label: 'Mulai', value: this.selectedScore.exam_start_time })
      this.selectedScores.push({ label: 'Selesai', value: this.selectedScore.exam_end_time })
      this.selectedScores.push({ label: 'Kode Lembar Soal', value: this.selectedScore.question_sheet_header_code })
      this.selectedScores.push({ label: 'Nilai/Skor', value: this.selectedScore.score })
      this.selectedScores.push({ label: 'Di Publish pada', value: this.selectedScore.publish_result_date })
      this.selectedScores.push({ label: 'Wali Kelas', value: this.selectedScore.homeroom_teacher })
      this.$refs.myRightBar.show()
    },

    loadMore() {
      this.page = this.page + 1;
      this.getList(this.params);
    },
  },

  mounted() {
  },
};
</script>

<style lang="scss">
.dtl-label{
  font-size: .8em;
  font-weight: bold;
}
.dtl-value{
  font-size: 1em;
}
</style>